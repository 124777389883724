.storyline-builder {
  display: grid;
  grid-template-columns: minmax(350px, 20%) minmax(200px, 80%);
  grid-template-rows: 100%;
  
  .storyline-builder-side-panel {
    background: var(--light-secondary-l-0, #f2f2f7);
    padding: 20px;
    overflow-y: scroll;
    border-right: solid 1px var(--light-secondary-l-1);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  }

  .storyline-builder-main-panel {
    overflow: scroll;
    padding: 30px;
  }
}

.storyline-button-overlay {
  position: absolute;
  display: grid;
  grid-template-columns: 50px 50px;
  height: 100%;
  gap: 10px;
  top: 0px;
  right: 0px;
  background-color: var(--light-secondary-l-1);
}

.step-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.menu-container {
  position: relative;
}

.more-menu {
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 200px;
  padding: 10px 0px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.more-menu-item {
  padding: 0px 20px;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: var(--light-secondary-l-0);
  gap: 10px;
  cursor: pointer;
  border-radius: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: none;
  align-self: unset;
}

.more-menu-item:hover:enabled {
  background-color: rgba(0, 0, 0, 0.03);
}
