.property-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .direction-vector {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap:10px;
        width: 100%;
        padding-top: 10px;

        .custom-field {
            height: 20px;
        }
    }

    .limit-checkbox-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .limit-values {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .limit-value {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .custom-field {
                height: 20px;
                width: 100px;
            }
        }
    }
}