.hierarchy-entry {
  border-radius: 10px;
  width: 100%;
  .entry-node {
    border: solid 2px transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    width: 100%;
  
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        min-width: 50px!important;
      }
    }
  }
  
  .children {
    border-left: solid 1px rgba(0, 0, 0, 0.2);
    margin-left: 25px;
  }
  
}
