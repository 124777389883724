.config-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#3a3a3c, 0.5);
    z-index: 100;
  }
  
  .config-dialog-overlay {
    position: absolute;
    width: 500px;
    max-height: 500px;
    top: calc(50% - 300px);
    left: calc(50% - 300px);
    background-color: white;
    padding: 50px;
    border-radius: 24px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    .config-container {
      display: flex;
      flex-direction: column;
      width: auto;
      padding-left: 25px;
      padding-top: 25px;
    }

    .config-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      white-space: nowrap;

      .config-input {
        width: 50%;
      }
    }
  }
  
  .config-dialog-buttons {
    gap: 30px;
    margin-top: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    button {
      min-width: 100px;
      padding: 0 20px;
      white-space: nowrap;
    }
  }
  