.interaction-panel {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  align-items: center;

  .interaction-properties {
    width: 50%;

    .custom-field {
      width: 100%;
    }

    .custom-dropdown {
      width: 100%;
    }
  }

  .interaction-preview {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    height: 500px;
    background: var(--light-secondary-l-0, #f2f2f7);
    border-radius: 10px;
  }
}
