:root {
  --light-primary-1-5: #0c6fe2;
  --light-text-light-theme-2: #8e8e93;
  --light-text-light-theme-1: #ceceda;
  --light-text-light-theme-0: #626466;
  --light-primary-l-6: #0056b9;
  --light-secondary-l-1: #e5e5ea;
  --light-secondary-l-2: #bbbbbf;
  --dark-system-d-red-4: #ff453a;
  border-radius: 8px;
  color: var(--light-text-light-theme-0);

  /* Web/Action Link 2 */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: var(--light-text-l-5, #1c1c1e);
  font-feature-settings: "clig" off, "liga" off;

  /* Web/Heading 2 */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.48px;
}

h2 {
  color: var(--light-text-light-theme-0);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}

h3 {
  color: var(--light-text-l-5, #1c1c1e);
  font-feature-settings: "clig" off, "liga" off;

  /* Web/Heading 2 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 133.333% */
  letter-spacing: 0.48px;
}
