.sign-in-backdrop {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#3a3a3c, 0.5);
  z-index: 100;
  align-items: center;
  justify-content: center;

  .sign-in-overlay {
    display: flex;
    flex-direction: column;
    background: white;
    width: 400px;
    height: 500px;
    border-radius: 24px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    align-items: center;
    justify-content: center;

    .logo {
      svg {
        width: 70px;
        height: 70px;
      }
    }

    .title {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;

      .bold {
        font-weight: bold;
        padding-right: 10px;
        font-size: 30px;
      }

      .regular {
        font-weight: normal;
        font-size: 30px;
      }
    }

    button {
      display: flex;
      width: 80%;
      margin-top: 20px;

      svg {
        margin-right: 10px;
        margin-top: 5px;
      }

      align-items: center;
      justify-content: center;
    }
  }
}
