.storyline-node-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
}

.content-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    justify-content: flex-start;
}

.node-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}
