.component-property-container {
  display: flex;
  flex-direction: column;
  border-radius: 30px 30px 10px 10px;

  .component-property-title {
    display: flex;
    flex-direction: row;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);

    .title {
      font-size: large;
      width: fill-available;
      padding-left: 20px;
    }

    button {
      padding-right: 20px;
      border-radius: 0 30px 30px 0;
      align-items: center;
      justify-content: center;
      width: 120px;
    }
  }

  .component-property-children {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    align-items: start;
  }
}
