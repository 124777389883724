.hamburger-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F8F8FB;
  border-radius: 8px;
  padding: 5px;

  .horizontal-container {
    display: flex;
    align-items: center;

    svg {
      padding-left: 10px;
    }

    input {
      background: transparent;
      box-shadow: none;
      padding: none;
      width: 150px;
      font-weight: 800;
    }
    
    .v-line {
      width: 3px;
      height: 30px;
      background: #D1D1D6;
      margin-left: 10px;
      margin-right: 10px;
    }

    button {
      padding-right: 10px;
    }
  }

  .button-container {
    width: 100%;
    gap: 10px;

    button {
      white-space: nowrap; /* Prevent text from wrapping to the next line */
      width: 100%;
      justify-content: flex-start;
      font-weight: 600;
      color: var(--light-primary-1-5);

      svg {
        padding-left: 10px;
        padding-right: 20px;
      }
    }
  }
}