// set the height of the html, body, and #root to 100% so that the entire page is filled
html, body, #root {
  height: 100%;
}

#root {
  display: grid;
  // navbar at top and content below
  grid-template-rows: 98px calc(100% - 98px);

  .navigation {
    min-width: 600px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 10;

    .hamburger-menu {
      position: absolute;
      top: 0%;
      left: 0%;
      margin-left: 20px;
      margin-top: 20px;
    }

    // page navigation tab buttons
    ul {
      display: flex;
      flex-direction: row;
      gap: 60px;

      li {
        list-style-type: none;

        a {
          height: 60px;
          width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-radius: 10px;
          text-decoration: none;

          &:hover {
            background-color: rgba(0, 0, 0, 0.025);
          }

          &:visited {
            color: black;
          }
        }
      }
    }
  }
}