.device-properties-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#3a3a3c, 0.5);
    z-index: 100;
  }
  
  .device-properties-overlay {
    position: absolute;
    width: 500px;
    max-height: 500px;
    top: calc(50% - 300px);
    left: calc(50% - 300px);
    background-color: white;
    padding: 50px;
    border-radius: 24px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    .device-properties {
      display: grid;
      grid-template-columns: 1fr 60px;
      width: 100%;
      padding-top: 25px;

      .custom-field {
        width: 100%;
      }

      button {
        margin-left: 10px;
      }
    }

    .apply-button-container {
      display: flex;
      width: 100%;
      margin-top: 100px;
      height: 60px;
      align-items: center;
      justify-content: center;

      button.primary {
        width: 200px;
      }
    }
  }
  
  .config-dialog-buttons {
    gap: 30px;
    margin-top: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    button {
      min-width: 100px;
      padding: 0 20px;
      white-space: nowrap;
    }
  }
  