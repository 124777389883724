.propertyselection-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#3a3a3c, 0.5);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  .propertyselection-dialog-overlay {
    position: absolute;
    background-color: white;
    padding: 50px;
    padding-top: 10px;
    border-radius: 24px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    .propertyselection-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 50px;
      margin-bottom: 50px;
      gap: 50px;
      
      .propertyselection-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        
        border-radius: 24px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

        svg {
          width: 100px;
          height: 100px;
        }
      }
    }

    .propertyselection-dialog-buttons {
      margin-top: 20px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        width: 50%;
        white-space: nowrap;
      }
    }
  }
}
