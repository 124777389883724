.custom-field {
  display: flex;

  .custom-field-input {
    border: 1px solid black;
    border-style: initial;
    outline: auto;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    color: var(--light-secondary-l-white);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    display: block;
    align-items: center;
    border-radius: 16px;
    width: 100%;
      
    box-sizing: border-box;
    background: var(--light-secondary-l-0, #f2f2f7);
    border: 1px solid;
    outline: none;

    &:focus {
      outline: auto;
    }

    &:disabled {
      cursor: initial;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: none;
    }
  }
}