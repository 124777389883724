.scene-editor-grid {
  display: grid;
  grid-template-columns: minmax(500px, 20%) minmax(200px, 80%);
  grid-template-rows: 100%;

  .sidepanel {
    display: flex;
    flex-direction: column;
    background: var(--light-secondary-l-0, #f2f2f7);
    padding: 20px;
    border-right: solid 1px var(--light-secondary-l-1);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);

    .device-list {
      overflow-y: scroll;
      overflow-x: hidden;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;

      // active device list
      .secondary {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 50px 1fr 50px;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);

        svg {
          width: 100%;
          height: 50%;
        }

        .device-name {
          width: 100%;
          text-align: left;
        }
      }
    }

    // import 3D file button
    .upload-button {
      width: 100%;
      padding-top: 20px;
      .primary {
        width: 100%;
      }
    }
  }
}
