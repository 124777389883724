.property-button-container {
  display: grid;
  grid-template-columns: 25% auto 20%;

  width: 100%;
  height: 60px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

  background-color: white;

  align-items: center;

  .property-icon {
    height: 100%;
    width: 100%;
    display: flex;
    background: #f8f8fb;
    border-radius: 30px 0 0 30px;
    align-items: center;
    justify-content: center;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .open {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  .delete {
    height: 100%;
    width: 100%;

    border-radius: 0 30px 30px 0;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
