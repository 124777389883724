body {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--light-secondary-l-2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--light-text-light-theme-0);
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track-piece {
    display: none;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
  ::-webkit-resizer {
    display: none;
  }
}

// base button style
button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-style: none;
  height: 50px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;

  &:disabled {
    cursor: initial;
    opacity: 0.5;
  }
}

// primary button style
button.primary {
  background: var(
    --light-primary-l-gradient,
    linear-gradient(180deg, #288bfe 0%, #0c6fe2 100%)
  );
  color: var(--light-secondary-l-white, #fff);

  &:hover {
    background: var(
      --light-primary-l-gradient-inverted,
      linear-gradient(180deg, #0c6fe2 0%, #288bfe 100%)
    );
  }
}

// secondary button style
button.secondary {
  background: var(--light-secondary-l-0, #f2f2f7);
  color: var(--light-primary-1-5);
}

input[type="text"] {
  outline: none;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  background: var(--light-secondary-l-0, #f2f2f7);
  color: var(--light-secondary-l-white);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: block;
  align-items: center;
  padding: 14px 18px;
  letter-spacing: 0.28px;
}

select {
  outline: none;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  background: var(--light-secondary-l-0, #f2f2f7);
  color: var(--light-secondary-l-white);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: block;
  align-items: center;
  padding: 14px 18px;
  letter-spacing: 0.28px;

  cursor: pointer;
}