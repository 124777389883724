.component-empty {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: 300;
    line-height: 150%;
    text-align: center;
  }
}