.editable-dropdown-field-content {
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  min-height: 70px;
}

.editable-dropdown-select {
  width: 100%;
  height: 100%;
}

.dropdown-button {
  width: 50px;
}

button {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  padding: 0px;
  margin: 0px;
  box-shadow: none;
  flex: none;
  align-self: auto;
  background-color: rgba(0, 0, 0, 0);
}

.text-button {
  width: auto;
  justify-content: left;
  padding: 10px;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.editable-dropdown-field-value {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  color: var(--light-secondary-l-white);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  display: block;
  padding: 14px 18px;
  align-items: center;
  border-radius: 16px;
  width: calc(
    100% - 20px
  ); /* Deducting 20px considering margin of 10px on left and right */
  box-sizing: border-box;
  margin: 10px; /* Adjust as needed */
  outline: none;
  cursor: initial;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
}
