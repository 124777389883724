.property-details {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title-container {
    display: grid;
    grid-template-columns: 10% 90%;
    width: 100%;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;

    button {
      height: 60px;

      svg {
        height: 50px;
        width: 50px;
      }
    }

    .title {
      display: flex;
      flex-direction: row;
      border-radius: 30px;
      margin-left: 20px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
      align-items: center;
      justify-content: center;

      .icon {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        svg {
          width: 35px;
          height: 35px;
          width: 50px;
          height: 100%;
          background: #f8f8fb;
          border-radius: 30px 0 0 30px;
          padding-right: 30px;
          padding-left: 30px;
          margin-right: 10px;
        }
      }

      h3 {
        width: fill-available;
        text-overflow: ellipsis;
      }
    }
  }
}
