.part-context-menu {
  position: fixed;
  background-color: white;
  display: flex;
  flex-direction: column;
  bottom: 0%;
  right:0%;
  z-index: 1;
  width: 20%;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-right: 40px;
  margin-bottom: 40px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);

  .property-overview {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .part-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      margin-bottom: 20px;

      .icons {
        display: flex;
        flex-direction: row;
        svg {
          width: 25px;
          height: 25px;
        }
      }

      .custom-field-input {
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-size: 25px;
        font-weight: bold;
      }

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .property-list {
      width: 100%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;

      gap: 20px;
      padding:10px;
    }

    .primary {
      margin-top: 20px;
      width: 100%;
    }
  }

  .create-part-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .primary {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      margin-top:20px;
  
      svg {
        width: 60%;
        height: 60%;
      }
    }
  }
}