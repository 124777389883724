
.scene-hierarchy-node-content {
  width: 100%;
  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;  /* Ensures buttons don't overlap the title */
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;

    .part-icon {
      width: 25px;
    }

    .node-name {
      white-space: nowrap; /* Prevents title from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ellipsis when the title is too long */
    max-width: 100%; /* Ensures the title does not overflow its container */
}

    .eye-icon-button {
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
    }
  }
}