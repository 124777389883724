.node-editor {
  h3 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding-bottom: 20px;
  }
}

.node-editor-children {
  padding-left: 40px;
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}

.node {
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  background-color: #FCFCFF;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
